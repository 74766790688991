import React from "react";
import "../index.css";
const Blog = ({ entries }) => {
  return (
    <section className="blog">
      <hr />
      {entries.map((p) => {
        return (
          <article key={p.id} className="blog">
            <p className="date">{p.date}</p>
            <a style={{ color: "whitesmoke" }} href={"/blog/" + p.id}>
              <h3>{p.title}</h3>
            </a>
            <hr />
          </article>
        );
      })}
    </section>
  );
};

export default Blog;
