export const entries = [
  {
    id: "what-is-wonderlabs",
    title: "What is Wonder Labs?",
    paragraphs: [
      "Wonder Labs is an investment vehicle for those seeking exposure to the potential of digital assets, cryptocurrency and the blockchain revolution.",
      "Our mission is to onboard people from the traditional economic mindset to the new private trustless paradigm shift by leveraging blockchain technology.",
    ],
    image: "",
    date: "04/01/2022",
  },
  {
    id: "our-thesis",
    title: "Our Thesis",
    paragraphs: [
      "Our investment thesis around cryptocurrencies is based on the premise that due to the early stages of blockchain mass adoption, which provides use cases with the potential of revolutionizing many different industries, and because of the monetary policy of central banks around the world, the long-term trend is bullish.",
      "The maturing of this new asset class and the cronical excess -and increasing- global liquidity implies that a good portion of fiat money will seek allocation in assets which provide a safe haven against inflation, taxation, and confiscation.",
      "Crypto fits in the austrian-economic theoretical model, and the technology has proven itself durable, thermodynamic and antifragile throughout more than a decade, growing in an organically exponential fashion.",
      "With this in mind, we believe that despite the recent bull market, we're still in a historical opportunity to obtain considerable returns on investment.",
    ],
    image: "",
    date: "04/01/2022",
  },
  {
    id: "our-proposal",
    title: "Our Proposal",
    paragraphs: [
      "We offer our investors financial exposure to crypto assets to maximize capital appreciation.",
      "Our team combines fundamental and technical analysis, complemented with a macro-financial general vision, and the tracking of industry-related events.",
      "We use a decentralized asset management platform running on Ethereum, to leverage blockchain's unique transparency and security properties, on which depositors can track and audit everything that is done by managers, and safely deposit and redeem shares when convenient.",
      "More details regarding our technical operating methodology can be found on our fund section."
    ],
    image: "",
    date: "04/01/2022",
  },
];
