import React from "react";

const Footer = () => {
  return (
    <section className="footer">
      <div className="row">
        <img
          width="100%"
          height="auto"
          src={require("../images/waves.png")}
          alt="Wonder Labs Footer"
        />
      </div>
    </section>
  );
};

export default Footer;
