import React from "react";
import "../index.css";

const Home = () => {
  return (
    <section className="home">
      <div className="container">
        <div className="row">
          <div className="col col-sm-11">
            <h1 className="home">Leverage blockchain</h1>
          </div>
        </div>
        <div className="row">
          <div className="col col-sm-5">
            <h1 className="home">Technology</h1>
          </div>
        </div>
        <div className="row">
          <div className="col col-sm-11">
            <h1 className="home">to power your</h1>
          </div>
        </div>
        <div className="row">
          <div className="col col-sm-10">
            <h1 className="home">economic energy.</h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
