import React from "react";
import "../index.css";

const NotFound = () => {
  return (
    <section className="home">
      <div className="container">
        <div className="row">
          <div className="col">
            <h1 className="notfound">404</h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
