import { useState, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
//
import Header from "./components/Header";
import Loading from "./components/Loading";
import Home from "./components/Home";
import Blog from "./components/Blog";
import BlogEntry from "./components/BlogEntry";
import Fund from "./components/Fund";
import Footer from "./components/Footer";
import NotFound from "./components/404";
import { entries } from "./blog/entries";
//
import "./index.css";
//
function App() {
  const location = useLocation();
  const { pathname } = location;
  const loc = pathname.split("/")[1];
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <main>
        <header>
          <Header location={loc} />
        </header>
        <Route
          render={({ location }) => (
            <TransitionGroup>
              <CSSTransition
                timeout={3000}
                in={!loading}
                classNames="fade"
                key={location.key}
              >
                <Switch>
                  <Route exact path="/">
                    <Home />
                  </Route>
                  <Route exact path="/blog">
                    <Blog entries={entries} />
                  </Route>
                  <Route
                    exact
                    path="/blog/:id"
                    render={({ match }) => (
                      <BlogEntry
                        entry={entries.find((p) => p.id === match.params.id)}
                        key={match.params.id}
                      />
                    )}
                  ></Route>
                  <Route exact path="/fund">
                    <Fund />
                  </Route>
                  <Route>
                    <NotFound />
                  </Route>
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </main>
      <Footer />
    </>
  );
}

export default App;
