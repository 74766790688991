import React from "react";

const BlogEntry = ({ entry }) => {
  const { title, subtitle, paragraphs, date } = entry;
  return (
    <section className="blogentry">
      <a href="/blog">
        <i className="gg-arrow-left"></i>
      </a>
      {date && <p className="date">{date}</p>}
      <h1>{title}</h1>
      {subtitle && <h4>{subtitle}</h4>}
      {paragraphs.map((paragraph) => {
        return <p key={paragraph}>{paragraph}</p>;
      })}
    </section>
  );
};

export default BlogEntry;
