import React from "react";
import "../index.css";
const Header = (loc) => {
  const { location } = loc;
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col col-sm-6">
          <img
            height="120"
            width="auto"
            className="logo"
            src={require("../images/header.png")}
            alt="Wonder Labs Header"
          />
        </div>
        <div className="col-4 col-sm-2">
          <a href="/">
            <button id="home" className={location === "" ? "btn active" : "btn"}>
              Home
            </button>
          </a>
        </div>
        <div className="col-4 col-sm-2">
          <a href="/blog">
            <button id="blog" className={location === "blog" ? "btn active" : "btn"}>
              Blog
            </button>
          </a>
        </div>
        <div className="col-4 col-sm-2">
          <a href="/fund">
            <button id="fund" className={location === "fund" ? "btn active" : "btn"} onClick={() => openInNewTab("https://app.enzyme.finance/vault/0xaf408c0bf86bf2eb69470139758483bf367d79ce/")}>
              Fund
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
