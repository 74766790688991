import React from "react";
const enzymeUrl =
  "https://app.enzyme.finance/vault/0xaf408c0bf86bf2eb69470139758483bf367d79ce/overview";

const Fund = () => {
  return (
    <section className="fund">
      <iframe src={enzymeUrl} title="Fund" width="100%" height="1000px"></iframe>
    </section>
  );
};

export default Fund;
