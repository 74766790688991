import React from "react";
import "../index.css";
const Loading = () => {
  return (
    <section className="loading">
      <img
        className="loading-img"
        src={require("../images/whitespinner.png")}
        alt="White spinner"
      />
    </section>
  );
};

export default Loading;
